import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`:30 to establish a complex max of:`}</p>
    <p>{`1-Power Clean`}</p>
    <p>{`1-Front Squat`}</p>
    <p>{`3-Back Squats`}</p>
    <p>{`1-Overhead Squat`}</p>
    <p>{`OR`}</p>
    <p>{`10:00 EMOM of:`}</p>
    <p>{`5 Jumping Lunges per leg, alternating`}</p>
    <p>{`10 Burpees `}<em parentName="p">{`(8 for intermediate, 5 for beginner)`}</em></p>
    <p>{`then,`}</p>
    <p>{`2 Mile Run for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      